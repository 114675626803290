
/* link Style */
button, a:link, a:visited, a:active, a:focus { text-decoration: none; }
a:hover { text-decoration: none; }
button:hover { text-decoration: none; }


.buttons { position: relative; display: inline-block; box-sizing: border-box; padding: 0px 12px 2px 12px; min-width: 100px; font-size: rem(14); font-weight: 500; color: #444444; line-height: em(32, 14); text-align: center; vertical-align: middle; border-radius: 4px; background-color: #fff; box-shadow: inset 0px 0px 0px 1px #B8BEC3;
	@include transition(border-color 0.2s, background-color 0.2s);
	+ .buttons { margin-left: 4px; }

	.txt { position: relative; left: 0; font-size: rem(14); font-weight: 500; color: #444444; line-height: em(32, 14);
		@include transition(left .2s, opacity .2s);
	}

	&:hover { border-color: #B8BEC3; background-color: rgba(184, 190, 195, 0.2); }
	&:active { border-color: #B8BEC3; background-color: rgba(184, 190, 195, 0.4); }

	&:disabled,
	&[disabled] { opacity: 0.3; }

	// size
	&[data-size="s"] { padding: 0 8px; min-width: 60px; font-size: rem(12); line-height: em(24, 12) !important;
		.txt { font-size: rem(12); line-height: em(24, 12) !important; }
	}
	&[data-size="l"] { line-height: em(38, 14); 
		.txt { line-height: em(38, 14); }
	}


	&[data-action="submit"] { color: #fff; background-color: #52545C;
		&:hover { color: #fff; background-color: #38393A; }
		&:active { color: #fff; background-color: #111213; }

		.txt { color: #fff; }
	}


	// loading init
	&:before { content: ''; visibility: hidden; display: block; margin: -0.6em 0 0 -0.5em; height: 1em; width: 1em;
		position: absolute; top: 50%; left: -100%;
		border: 2px solid transparent; border-top-color: #fff; border-left-color: #fff; border-radius: 50%;
		animation: loading .5s linear infinite;

		@include transition(left 0.2s);
 	}


	// loading state
	&[data-loading="true"] {
		&:before { left: 50%; visibility: visible; }

		&:disabled,
		&[disabled] { opacity: 0.3; }


		.txt { opacity: 0; left: 100%; }
	}
}