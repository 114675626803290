
select { appearance: none; display: inline-block; box-sizing: border-box; padding-right: 14px; font-size: rem(15); font-weight: 400; color: #000000; line-height: em(21, 15);
	background: url('/assets/images/bg_select@2x.png') right 55% no-repeat; background-size: 9px 5px;

	&::-ms-expand { display: none; }
}


.form-label { display: inline-block; box-sizing: border-box; padding: 8px 0; font-size: rem(14); font-weight: 400; color: #333333; line-height: em(18, 14); }

.invalid-message { display: block; margin-top: 4px; font-size: rem(12); font-weight: 400; color: #FF2D47; line-height: em(18, 12); }




.text-field { display: inline-block; box-sizing: border-box; padding: 0 11px; height: 34px; font-size: rem(12); font-weight: 400; color: #000000; line-height: em(32, 12); border: 1px solid #DDDDDD; border-radius: 4px; background-color: #FEFEFE;
	@include transition(border-color 0.3s);

	&::placeholder { color: #AAAAAA !important; }
	&:focus { border-color: #000; }

	&[data-invalid] { border-color: #FF1053; }
}

.text-area { display: inline-block; box-sizing: border-box; padding: 8px 11px; height: 102px; font-size: rem(12); font-weight: 400; color: #000000; line-height: em(18, 12); border: 1px solid #DDDDDD; border-radius: 4px; background-color: #FEFEFE;
	@include transition(border-color 0.3s);

	&::placeholder { color: #AAAAAA !important; }
	&:focus { border-color: #000; }

	&[data-invalid] { border-color: #FF1053; }
}



.segmented-control { overflow: hidden; display: flex; justify-content: center; position: relative; box-sizing: border-box; border-radius: 4px;
	input[type=radio] { position: absolute; left: -9999px;
		+ label { cursor: pointer; flex: 1; display: flex; justify-content: center; align-items: center; box-sizing: border-box; padding: 3px 8px; font-size: rem(12); font-weight: 500; color: #383838; text-align: center; line-height: em(28, 12); white-space: nowrap; border: 0; background-color: #F3F5F7;
			@include transition(color 0.2s, background-color 0.2s);

			&:hover { color: #383838; background-color: #DDDFE1; }
		}

		&:checked {
			+ label { color: #fff; font-weight: 700; background-color: #000; }
		}
	}

	+ .segmented-control { margin-left: 3px; }
}


.form-radio {
	> .item { position: relative;

	}

	.radio { position: absolute; left: -9999px;
		+ label { display: block; box-sizing: border-box; 
			&::before { content: ''; display: block; margin: 9px 0; height: 16px; width: 16px; border: 1px solid #CCCCCC; border-radius: 16px;
				background-color: #fff;
				box-shadow: 0 0 2px 1px #EEEEEE;
				
				@include transition(border-color 0.3s, background-image 0.3s);
			}

			.txt { display: block; padding: 8px 0; font-size: rem(12); font-weight: 400; color: #333333; line-height: em(18, 12); }
		}

		&:checked {
			+ label {
				&::before { border-color: #3561A0; background: #fff inline-svg("icon-circle-01", #3561A0) center center no-repeat; background-size: 6px 6px; }

				.txt { font-weight: 500; }
			}
		}
	}


	&[data-inline="true"] {
		> .item { margin: 0 !important;
			~ .item { margin-left: 16px !important; }
		}

		.radio {
			+ label { display: inline-block;
				&::before { display: inline-block; margin-right: 8px; vertical-align: middle; }

				.txt { display: inline-block; vertical-align: middle; }
			}
		}
	}
}



/// 
.filter-events { display: block; box-sizing: border-box; margin: 10px 0; padding: 0 10px; 
	.hstack { display: flex; justify-content: space-between;

	}
}



.form-item {
	.form-label { display: block; }
	> .item { display: block;
		.text-field,
		.text-area { display: block; width: 100%; }
	}
}

.forms { position: relative; display: block; box-sizing: border-box; padding: 0 22px;
	&::before { content: ''; opacity: 0; visibility: hidden; position: absolute; top: 0; right: 0; bottom: 0; left: 0; z-index: z("default"); background-color: rgba(255, 255, 255, 0.7);
		@include transition(opacity 0.3s, visibility 0.3s)
	}
	&::after { content: ''; opacity: 0; visibility: hidden; display: block; margin: -0.8em 0 0 -0.75em; height: 1.5em; width: 1.5em;
		position: absolute; top: 50%; left: 50%; z-index: z("default") + 1;
		border: 2px solid transparent; border-top-color: #000; border-left-color: #000; border-radius: 50%;
		animation: loading .5s linear infinite;
	}

	&[data-loading="true"] {
		&::before,
		&::after { opacity: 1; visibility: visible; }
	}

	.form-actions { display: flex; margin-top: 16px;
		.buttons { flex: 1; }
	}
}




/// 로그인 입력 폼
.login-form { display: block; box-sizing: border-box; margin: 0 auto; padding-top: 34px; width: 100%;
	@include respond-to("desktop") { width: 480px; }

	.form-item { display: block; width: 100%;
		+ .form-item { margin-top: 5px; }
	}
}




