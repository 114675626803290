@keyframes marquee-text {
	0% { left: 95%; }
	100% { left: -450%; }
}

@keyframes fade {
	0% { opacity: 0; }
	50% { opacity: 1; }
	100% { opacity: 0; }
}

@keyframes loading {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}
