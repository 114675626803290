.layout-wrap { 
	@include respond-to("desktop") { margin: 0 auto; min-width: 1024px; max-width: 1400px; }
}

.layout-header { display: flex; flex-direction: column; justify-content: flex-start; box-sizing: border-box; padding: 39px 22px; height: 151px;
	.logo { overflow: hidden; display: inline-block; box-sizing: border-box; height: 31px; width: 139px; text-indent: -9999px; background: inline-svg("logo-03", #000) center center no-repeat; background-size: 100%; }
	.admin { display: inline-block; margin: 0 0 0 10px; font-size: rem(20); font-weight: 700; color: #000; line-height: em(31, 20); vertical-align: middle; text-transform: uppercase; }

	.actions { margin-top: 10px; }

	@include respond-to("desktop") { flex-direction: row; justify-content: flex-end; }

	&.admin {
		@include respond-to("desktop") { justify-content: space-between; }
	}
}


.layout-footer { position: relative;
	display: flex;
	flex-direction: column;
	gap: 50px;
	box-sizing: border-box; padding: 128px 28px 19px 28px; border-top: 2px solid #D8D8D8;
	
	@include respond-to("desktop") {
		flex-direction: row;
		justify-content: space-between;
		padding: 179px 28px 27px 28px; }

	.info {
		display: flex;
		flex-wrap: wrap;
		gap: 30px;

		.item {
			display: block;
			min-width: 90px;
		}
		
		dt { display: block; font-size: rem(12); font-weight: 700; color: #000000; line-height: em(17, 12); }
		dd { display: block; margin-top: 17px; font-size: rem(12); font-weight: 400; color: #000000; line-height: em(17, 12);
			a { font-size: rem(12); font-weight: 400; color: #000000; line-height: em(17, 12); }
		}


		@include respond-to("desktop") {
			display: flex;
			gap: 60px;
			
			.item { min-width: 86px; }
		}
	}

	.copyright { display: block; margin-top: 45px; font-size: rem(12); font-weight: 400; color: #000000; line-height: em(17, 12);
		@include respond-to("desktop") { position: absolute; top: 140px; left: 28px;; margin: 0; }
	}
}



.layout-contents-container { display: block; box-sizing: border-box;
	.page-title { display: block; box-sizing: border-box; font-size: rem(15); font-weight: 500; color: #000; text-align: center; line-height: em(20, 15); }
}


.layout-headless-container { display: flex; flex-direction: column; justify-content: center; box-sizing: border-box; padding: 0 22px; min-height: 100vh; background-color: #fff;
	.logo { overflow: hidden; display: block; box-sizing: border-box; margin: 0 auto; height: 31px; width: 139px; text-indent: -9999px; background: inline-svg("logo-03", #000) center center no-repeat; background-size: 100%; }
}