.page-home { position: relative; padding-top: 20px;

}

.menus { display: block; border-top: 2px solid #D8D8D8;
	.btn-toggle { display: block; box-sizing: border-box; padding: 10px 22px; width: 100%; font-size: rem(15); font-weight: 700; color: #000000; line-height: em(21, 15); text-align: left; text-transform: capitalize; }
	
	.expand-container { opacity: 0; overflow: hidden; display: block; box-sizing: border-box; padding: 0 22px 0px 22px; max-height: 0;
		font-size: rem(14); font-weight: 400; color: #000000; line-height: em(23, 14);
		strong { font-weight: 700; vertical-align: baseline; }

		// @include transition(opacity 0.2s $cubic-bezier, padding 0.2s $cubic-bezier, max-height 0.2s $cubic-bezier);

		&[data-expand="true"] { opacity: 1; padding: 10px 22px 80px 22px; max-height: 5000px; }

		.list-photo { margin: 0 -22px 13px -22px;
			.slick-dots { position: absolute; right: 0; bottom: 14px; left: 0; text-align: center;
				> li { display: inline-block; margin: 0 7px;

					button { display: block; height: 10px; width: 10px; text-indent: -9999px; border-radius: 10px; background-color: rgba(#FFFFFF, 0.6); }

					&.slick-active {
						button { background-color: #fff; }
					}
				}
			}

			.slick-prev { display: none !important; }
			.slick-next { display: none !important; }

			@include respond-to("desktop") { margin: 0 0 30px 0;
				.slick-dots {
					> li { margin: 0 15px; }
				}

				.slick-next { position: absolute; top: 0; right: 0; bottom: 0; left: 0; display: block; width: 100%; text-indent: -9999px;
					display: block !important;
					cursor: url('/assets/images/cursor_arrow_01.png'), pointer;
				}
			}
		}
	}

	@include respond-to("desktop") { display: flex;
		.btn-toggle { min-width: 332px; }
		.expand-container { font-size: rem(15); line-height: em(23, 15);
			&[data-expand="true"] { padding-top: 13px; padding-bottom: 82px; }
		}
	}
}


.tbl-events { width: 100%; border-top: 2px solid #D8D8D8;
	thead {
		tr {
			th, td { padding: 13px 22px 12px 22px; font-size: rem(15); font-weight: 400; color: #000000; line-height: em(21, 15); text-align: left; }
			th { font-weight: 700; }
		}
	}

	tbody {
		tr {
			th, td { padding: 7px 22px 24px 22px; height: 140px; font-size: rem(14); font-weight: 400; color: #000000; line-height: em(25, 14); vertical-align: top; text-align: left; border-top: 1px solid #D8D8D8; }
		}
	}

	a { font-size: rem(14); font-weight: 400; color: #000000; line-height: em(25, 14); }
	.contents { line-height: em(25, 14); }
	.location { color: #8C8C8C; line-height: em(20, 14); }
	.host { color: #8C8C8C; line-height: em(25, 14); }
	.loading { margin: 0; }
	.btn-external { font-weight: 700; }


	@include respond-to("phone") { 
		thead { display: block; box-sizing: border-box; padding: 11px 22px; width: 100%;
			tr { display: flex; justify-content: space-between;
				th, td { padding: 0; font-size: rem(14); font-weight: 400; color: #000000; line-height: em(20, 14); text-align: left; }
				th { font-weight: 700; }
			}
		}

		tbody { display: block; 
			tr { position: relative; display: block; box-sizing: border-box; padding: 10px 22px; min-height: 300px; width: 100%; border-top: 1px solid #D8D8D8;
				th, td { display: block; box-sizing: border-box; padding: 0; height: auto; border: 0; }
			}
		}

		.location { margin-top: 10px; }
		.contents { margin-top: 17px; }
		.btn-external { position: absolute; right: 22px; bottom: 20px; }
	}
}


.btn-membership { display: block; box-sizing: border-box; margin: 0 -22px -80px -22px; font-size: rem(15); font-weight: 700; color: #000000; line-height: em(38, 15); text-align: center; border-top: 1px solid #D8D8D8;
	@include respond-to("desktop") { margin: 80px 0 0 0; border: 1px solid #D8D8D8; }
}

.tbl-membership-m { margin: 30px -22px; width: calc(100% + 44px);
	tbody {
		> tr {
			&:last-child {
				th, td { padding-bottom: 22px; }
			}

			th { padding: 0 20px 0 22px; font-size: rem(14); font-weight: 700; color: #000000; line-height: em(23, 14); vertical-align: top; text-align: left; white-space: nowrap;
				&[scope="col"] { padding-top: 10px; padding-bottom: 23px; border-top: 1px solid #D8D8D8; }
			}
			td { padding-right: 22px; font-size: rem(14); font-weight: 400; color: #000000; line-height: em(23, 14); vertical-align: middle; text-align: left; }
		}
	}
	@include respond-to("desktop") { display: none; }
}


.tbl-membership { display: table; margin-top: 85px; width: 100%; border-bottom: 1px solid #D8D8D8;
	> thead {
		> tr {
			th { font-size: rem(15); font-weight: 700; color: #000000; line-height: em(46, 15); text-align: left;
				+ th { padding-left: 28px; }

				span { font-weight: 400; }
			}
		}
	}

	> tbody {
		> tr {
			td { padding: 12px 0; font-size: rem(15); font-weight: 400; color: #000000; line-height: em(23, 15); vertical-align: top; text-align: left; border-top: 1px solid #D8D8D8;
				+ td { padding-left: 28px; }
			}
		}
	}

	@include respond-to("phone") { display: none; }
}