$rt-namespace: 'Toastify';
$rt-toast-width: 320px !default;
$rt-toast-background: #ffffff !default;
$rt-toast-min-height: auto !default;
$rt-toast-max-height: 800px !default;


$rt-color-default: rgba(255, 255, 255, 0.3) !default;
$rt-color-info: #3498db !default;
$rt-color-success: #e3891c !default;
$rt-color-warning: #f1c40f !default;
$rt-color-error: rgba(187, 51, 34, 0.9) !default;

$rt-color-progress-default: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55) !default;

$rt-mobile: "only screen and (max-width : 480px)" !default;
$rt-font-family: sans-serif !default;
$rt-z-index: 9999 !default;

